<template>
  <div>
    <!-- 跳转组 -->
    <div class="top">
      <span class="l">跳转组管理</span>
      <el-button
        type="primary"
        size="small"
        @click="
          dialogFormVisible = true;
          type = 'add';
        "
        >新增跳转组</el-button
      >
    </div>
    <el-table
      :data="tableData"
      border
      style="
        width: 100%;
        margin-top: 30px;
        max-height: 70vh;
        overflow-y: scroll;
      "
    >
      <el-table-column
        prop="jump_group_name"
        label="跳转组名称"
      ></el-table-column>
      <el-table-column
        label="跳转链接"
        prop="jump_group_https"
      ></el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            @change="switchChange(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="scope">
          <!-- <el-button size="small" style="margin-bottom:10px" type="primary">暂停使用</el-button> -->
          <el-button
            type="danger"
            size="small"
            @click.prevent="deleteRow(scope.$index, tableData)"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="handleClick(scope.row, scope.$index)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="type == 'add' ? '增加跳转组' : '编辑跳转组'"
      v-model="dialogFormVisible"
      width="500px"
    >
      <el-form :model="form">
        <el-form-item label="跳转组名称" :label-width="formLabelWidth">
          <el-input
            autocomplete="off"
            placeholder="请输入跳转组名称"
            v-model="form.jump_group_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转组链接" :label-width="formLabelWidth">
          <el-input
            autocomplete="off"
            placeholder="跳转组链接"
            v-model="form.jump_group_https"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <!-- <template> -->
          <el-switch v-model="form.status" active-color="#13ce66"></el-switch>
          <!-- </template> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { ElMessage } from "element-plus";
import {
  jumpGroupList,
  addjumpgroup,
  jumpGroupedel,
  jumpGroupedit,
  jumpGroupeupd,
} from "../request/api";
export default {
  async created() {
    this.value = this.value + "address_id=" + this.$route.query.address_id;
    this.address_id = this.$route.query.address_id;
    this.form.address_id = this.$route.query.address_id;
    this.pagetotal = await this.admList();
     this.pagetotal ? this.$emit("pagetotalClick",  this.pagetotal) : "";
  },
  props: ["page", "pagepage"],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      dialogFormVisible: false,
      type: "add",
      pagetotal:0,
      formLabelWidth: "85px",
      size: 10,
      form: {
        jump_group_name: "",
        jump_group_https: "",
        status: false,
      },
      tableData: [
          
        // {
        //   name: "张三",
        //   link: "sddf",
        //   is_use: true,
        // },
      ],
    };
  },
  methods: {
    switchChange(item) {
      jumpGroupeupd({
        id: item.id,
        status: item.status == true ? 1 : 2,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: res.code == 200 ? "success" : "info",
            message: res.msg,
          });
        }
      });
    },
    admList() {
      //   this.tableData = [];
      return new Promise((resolve, reject) => {
        jumpGroupList({
          page: this.page,
          limit: this.pagepage,
        }).then((res) => {
          resolve(res.total);
          this.tableData = res.list;
          this.tableData.forEach((item) => {
            item.status = item.status == 2 ? false : true;
          });
        });
      });
    },
    addcurse() {
      this.form.status = this.form.status ? 1 : 2;
      if (this.type == "add") {
        delete this.form.id;

        addjumpgroup(this.form).then((res) => {
          if (res.code == 200) {
            this.admList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.form = {};
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        jumpGroupedit(this.form).then((res) => {
          if (res.code == 200) {
            this.admList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.form.jump_group_name = "";
            this.form.jump_group_https = "";
            this.form.status = false;
            this.dialogFormVisible = false;
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        this.dialogFormVisible = false;
      }
    },
    handleClick(item, index) {
      this.dialogFormVisible = true;
      this.type = "revise";
      this.form.jump_group_name = item.jump_group_name;
      this.form.jump_group_https = item.jump_group_https;
      this.form.status = item.status == 1;
      this.form.id = item.id;
    },
    //删除
  async  deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
           jumpGroupedel({ id: rows[index].id }).then((res) => {
            if (res.code == 200) {
              rows.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
           this.$emit("pagetotalClick", this.pagetotal-1) 
            }
          
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang='less' scoped>
.el-input {
  width: 250px;
}
.el-form-item {
  display: flex;
}
.top {
  .l {
    font-size: 20px;
    font-weight: 400;
    color: #545454;
  }
  display: flex;
  justify-content: space-between;
}
</style>