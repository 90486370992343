<template>
  <!-- 门禁列表 -->
  <div class="accessControlList">
    <div style="display: flex; justify-content: space-between">
      <span>门禁列表</span>
      <el-button
        type="primary"
        style="margin-left: 30px"
        @click="
          dialogFormVisible = true;
          type = 'add';
        "
        >添加门禁</el-button
      >
    </div>
    <el-table
      :data="tableData"
      border
      style="
        width: 100%;
        margin-top: 40px;
        max-height: 70vh;
        overflow-y: scroll;
      "
    >
      <el-table-column prop="access_name" label="门禁名称"></el-table-column>
      <el-table-column prop="access_number" label="门禁设备号"></el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <span>{{
            scope.row.status == 0
              ? "未激活"
              : scope.row.status == 1
              ? "在线"
              : scope.row.status == 3
              ? "离线"
              : scope.row.status == 8
              ? "禁用"
              : "12"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="使用情况" width="100px">
        <template #default="scope">
          {{ scope.row.status == 1 ? "使用中" : "未使用" }}
        </template>
      </el-table-column>
      <!-- 新旧设备区分 -->
      <el-table-column prop="status" label="设备类型" width="100px">
        <template #default="scope">
          {{ scope.row.is_on == 1 ? "新设备" : "旧设备" }}
        </template>
      </el-table-column>
      <el-table-column prop="access_identifier" label="属性名"></el-table-column>
      <el-table-column prop="access_key" label="key"></el-table-column>
      <el-table-column prop="created_at" label="添加时间"></el-table-column>
      <el-table-column prop="jump_group_name" label="跳转"></el-table-column>
      <el-table-column prop="province" label="门禁二维码">
        <template #default="scope" style="position: relative">
          <div class="boxbox">
            <img :src="scope.row.image.path" alt ref="imgreload" />
            <img src="../../../public/bag.png" alt class="imagesbag" @click="reload(scope.row.id)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button @click="handleClick(scope.row)" type="primary" size="small">编辑</el-button>
          <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, tableData)">删除</el-button>
          <el-button type="warning" size="small" @click.prevent="chakanStatus(scope.row.id)" style="margin-top: 10px">
            查看状态</el-button
          >
          <el-button
            type="warning"
            size="small"
            @click.prevent="downloadCodeImg(scope.row.image.path)"
            style="margin-top: 10px"
            >下载二维码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹出编辑框 -->
    <el-dialog :title="type == 'add' ? '添加场地' : '编辑场地'" v-model="dialogFormVisible" width="500px">
      <el-form :model="form">
        <el-form-item label="门禁名称&emsp;">
          <el-input v-model="form.access_name" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="门禁设备号">
          <el-input v-model="form.access_number" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="属性名&emsp;&emsp;">
          <el-input v-model="form.access_identifier" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="key &emsp;&emsp;&emsp;">
          <el-input v-model="form.access_key" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="使用情况&emsp;">
          <el-select v-model="form.status" placeholder="场地状态">
            <el-option :label="item.name" :value="item.id" v-for="item in isuselist" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="设备类型&emsp;">
          <el-select v-model="form.is_on" placeholder="选择设备">
            <el-option :label="item.name" :value="item.id" v-for="item in driveStatus" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="跳转分组&emsp;">
          <el-select v-model="form.jump_group_id" placeholder="跳转分组">
            <el-option v-for="item in jump_group_list" :key="item.id" :label="item.jump_group_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="允许通过类型&emsp;">
          <el-select v-model="passType" multiple placeholder="请选择">
            <el-option label="普通用户" :value="0"></el-option>
            <el-option label="业主" :value="1"></el-option>
            <el-option label="员工" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  accessList,
  accessedit,
  accessDel,
  accessAdd,
  getStatus,
  reChangeQrCode,
  jumpGroupAll,
} from "../../request/api";
import { ElMessage } from "element-plus";
export default {
  props: ["page", "pagepage"],
  computed: {
    passType: {
      set(data) {
        console.log("修改值", data);
        this.form.type = data.join(",");
      },
      get() {
        if (this.form.type) {
          return this.form.type
            .replace("普通用户", "0")
            .replace("业主", "1")
            .replace("员工", "2")
            .split(",")
            .map((el) => parseInt(el));
        } else {
          return [];
        }
      },
    },
  },
  data() {
    return {
      driveStatus: [
        {
          name: "旧设备",
          id: 0,
        },
        {
          name: "新设备",
          id: 1,
        },
      ],
      isuselist: [
        {
          name: "使用中",
          id: 1,
        },
        {
          name: "未使用",
          id: 0,
        },
      ],

      issearch: true,
      accessControName: "",
      cahngdi: "",
      options: [
        { id: 1, lable: "充停" },
        { id: 2, lable: "充" },
        { id: 3, lable: "停" },
      ],
      typeoptions: "",
      tableData: [],
      jump_group_list: [],
      dialogFormVisible: false,
      form: {},
      type: "add",
      pageTotal: "",
    };
  },
  async created() {
    this.accessConList();
    jumpGroupAll().then((res) => {
      this.jump_group_list = res.list;
    });
  },
  methods: {
    reload(id) {
      reChangeQrCode({
        id: id,
        type: "access",
      }).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: "刷新成功",
            type: "success",
          });
          this.accessConList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    chakanStatus(id) {
      getStatus({ id: id }).then((res) => {
        if (res.code == 200) {
          ElMessage.success(res.msg);
          this.accessConList();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    // 下载图片
    downloadCodeImg(src) {
      window.open(src, "_blank");
    },
    accessConList() {
      return new Promise((resolve, reject) => {
        accessList({
          page: this.page,
          page_size: this.pagepage,
          address_id: this.$route.query.id,
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list.address_access_list;
        });
      });
    },
    handleClick(item) {
      this.type = "revise";
      this.dialogFormVisible = true;
      this.form.access_name = item.access_name;
      this.form.access_number = item.access_number;
      this.form.status = item.status;
      this.form.address_id = item.address_id;
      this.form.access_key = item.access_key;
      this.form.access_identifier = item.access_identifier;
      this.form.id = item.id;
      this.form.jump_group_id = item.jump_group_id;
      this.form.is_on = item.is_on;
      this.form.type = item.type;
    },
    addcurse() {
      this.jump_group_list.forEach((item) => {
        if (this.form.jump_group_id == item.id) {
          this.form.jump_group_https = item.jump_group_https;
          this.form.jump_group_name = item.jump_group_name;
        }
      });
      // this.form.status = this.form.status == "使用中" ? 1 : 0;
      if (this.type == "add") {
        console.log(this.form, this.form.jump_group_name);
        this.form.address_id = this.$route.query.id;
        delete this.form.id;
        accessAdd(this.form).then((res) => {
          if (res.code == 200) {
            this.accessConList();
            ElMessage.success(res.msg);
            this.dialogFormVisible = false;
            this.form = {};
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        accessedit(this.form).then((res) => {
          if (res.code == 200) {
            this.accessConList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    },
    //   删除
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          accessDel({ id: rows[index].id }).then((res) => {
            if (res.code == 200) {
              this.accessConList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  watch: {
    page() {
      this.accessConList();
    },
    pagepage() {
      this.accessConList();
    },
  },
};
</script>

<style lang="less" scoped>
.className {
  background: red;
}

.imagesbag {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  display: none;
}

.boxbox:hover .imagesbag {
  display: block;
}

.accessControlList {
  /deep/.cell {
    text-align: center;
  }
  img {
    width: 100px;
    height: 100px;
  }
}
</style>
